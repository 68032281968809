$cursor: (
  normal: default,
  pointer: pointer,
  not-allowed: not-allowed,
  text: text,
);

.new-design {
  margin: 0;
  padding: 0;

  @each $name, $value in $cursor {
    &.cursor-#{$name} {
      cursor: $value !important;
    }
  }
}