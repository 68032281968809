$baseSpace: 1px;
$spaces: (
);

// Generate spacing from 0px to 30px in 1px increments
@for $i from 0 through 30 {
  $spaces: map-merge($spaces, ($i: $baseSpace * $i));
}

// Extend with classic spacing values (4-based increments)
$spaces: map-merge($spaces, (32: 32px,
      36: 36px,
      40: 40px,
      44: 44px,
      48: 48px,
      56: 56px,
      64: 64px,
      72: 72px,
      80: 80px,
      96: 96px,
      112: 112px,
      128: 128px));

$new-design: (
  "m": "margin",
  "p": "padding"
);

$directions: (
  "t": "top",
  "r": "right",
  "b": "bottom",
  "l": "left"
);

.new-design {
  @each $name, $space in $spaces {

    // Spacing for all sides
    @each $short, $property in $new-design {
      &.#{$short}-#{$name} {
        #{$property}: $space !important;
      }
    }

    // Directional spacing
    @each $short, $property in $new-design {
      @each $d-short, $direction in $directions {
        &.#{$short}#{$d-short}-#{$name} {
          #{$property}-#{$direction}: $space !important;
        }
      }
    }

    // Gap spacing
    &.gap-#{$name} {
      gap: $space !important;
    }
  }
}