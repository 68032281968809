$colors: (
  "base1": #000,
  "base2": #000,
  "main-accent": #405E1F,
  "primary": #fff,
  "secondary": #fff,
  "tertiary": #40424D,
  "quaternary": #202126,
  'accent1': rgba(255, 255, 255, 0.8),
  'accent2': rgba(0, 0, 0, 0.50),
  'accent3': rgba(255, 255, 255, 0.1),
  'accent4': rgba(255, 255, 255, 0.08),
  'accent5': #EFF0EF,
  'transparent': transparent,
  'danger': #FF4D4F,
);

@mixin generate-classes($property, $prefix, $state: null) {
  @each $name, $color in $colors {
    $class: if($state, "#{$prefix}-#{$name}-#{$state}", "#{$prefix}-#{$name}");

    &.#{$class}#{if($state, ":#{$state}", " ")} {
#{$property}: $color !important;
  }
}
}

// add constants for colors
:root {
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }
}

.new-design {
  margin: 0;
  padding: 0;

  @include generate-classes(background-color, "bg");
  @include generate-classes(color, "text");
  @include generate-classes(border-color, "border");
  @include generate-classes(fill, "fill");

  @include generate-classes(background-color, "bg", "hover");
  @include generate-classes(background-color, "bg", "focus");
  @include generate-classes(background-color, "bg", "active");
  @include generate-classes(background-color, "bg", "disabled");

  @include generate-classes(color, "text", "hover");
  @include generate-classes(color, "text", "focus");
  @include generate-classes(color, "text", "active");
  @include generate-classes(color, "text", "disabled");

  @include generate-classes(border-color, "border", "hover");
  @include generate-classes(border-color, "border", "focus");
  @include generate-classes(border-color, "border", "active");
  @include generate-classes(border-color, "border", "disabled");

  @include generate-classes(fill, "fill", "hover");
  @include generate-classes(fill, "fill", "focus");
  @include generate-classes(fill, "fill", "active");
  @include generate-classes(fill, "fill", "disabled");
}

// as example of usage 
// <div class="new-design bg-base1">Background color base1</div>
// <div class="new-design text-base2">Text color base2</div>
// <div class="new-design border-main-accent">Border color main-accent</div>
// <div class="new-design fill-primary">Fill color primary</div>
// <div class="new-design bg-base1-hover">Background color base1 on hover</div>
// <div class="new-design text-base2-hover">Text color base2 on hover</div>
// <div class="new-design border-main-accent-hover">Border color main-accent on hover</div>
// <div class="new-design fill-primary-hover">Fill color primary on hover</div>
// <div class="new-design bg-base1-focus">Background color base1 on focus</div>
// <div class="new-design text-base2-focus">Text color base2 on focus</div>
// <div class="new-design border-main-accent-focus">Border color main-accent on focus</div>
// <div class="new-design fill-primary-focus">Fill color primary on focus</div>
// <div class="new-design bg-base1-active">Background color base1 on active</div>
// <div class="new-design text-base2-active">Text color base2 on active</div>
// <div class="new-design border-main-accent-active">Border color main-accent on active</div>
// <div class="new-design fill-primary-active">Fill color primary on active</div>
// <div class="new-design bg-base1-disabled">Background color base1 on disabled</div>
// <div class="new-design text-base2-disabled">Text color base2 on disabled</div>