.switch-tooltip+.customTextField {
    overflow: hidden;
}

.switch-tooltip+.customTextField input.inputField {
    padding-right: 26px;
}

.customTextField .inputLabel {
    white-space: nowrap;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.required {
        &:after {
            content: '*';
            margin-left: 4px;
            color: #000;
        }
    }
}

.customTextField .inputLabel[data-shrink=true] {
    max-width: 510px;
    width: 100%;
    overflow: visible;
    text-overflow: unset;

}

.customTextField label {
    line-height: 1.5;
}

.customTextField label[data-shrink=false] {
    color: #000 !important;
    margin-left: 10px;
    z-index: 2;
}

.customTextField label:not(.custom-label)[data-shrink=true] {
    color: #000 !important;
}

.customTextField input.inputField {
    font-size: 14px !important;
    line-height: 18px;
    padding: 6px 0 7px;
    height: 1.1876em;
}

.customTextField .inputRoot.Mui-disabled input,
.customTextField .inputRoot.Mui-disabled input::placeholder {
    color: #000 !important;
    -webkit-text-fill-color: #000 !important;
}

.customTextField input:-webkit-autofill,
.customTextField input:-webkit-autofill:hover,
.customTextField input:-webkit-autofill:focus,
.customTextField input:-webkit-autofill:active {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.customTextField .inputRoot.MuiInput-disabled-18:before,
.customTextField .inputRoot.MuiInput-disabled-18:after {
    border-bottom: none !important;
}

.customTextField .inputRoot {
    border: 1px solid #8D968D !important;
    border-radius: 8px;
    padding: 0 10px;
    background-color: #FAFAFA;
}
.customTextField .inputRoot.Mui-focused {
    border: 1px solid #9AB20F !important;
    background: #F1F9E8;
    box-shadow: 0px 0px 0px 2px #C8E6A4
}

.MuiInputBase-root.MuiInput-root.inputRoot.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    position: relative;
    min-height: 34px;

    &:after,
    &:before {
        border-bottom: none;
    }
}

@-webkit-keyframes autofill {
    to {
        color: #000;
        background: transparent;
    }
}

.inputCustomWarning {
    color: #f4516c;
    font-weight: 400;
    padding-left: 1.6rem;
    margin: 8px 0 0;
}

.search-ip-btn {
    line-height: 0.3 !important;
}