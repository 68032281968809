$borders: (
  '0': none,
  '1': 1px,
  '2': 2px,
  '3': 3px,
  '4': 4px,
  '5': 5px,
);

$directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left'
);

.new-design {
  @each $name, $border in $borders {
    $class: "border-#{$name}";

    &.#{$class} {
      // solid border
      border-width: $border !important;
      border-style: solid;
    }

    @each $abbr, $dir in $directions {
      &.border-#{$abbr}-#{$name} {
        border-#{$dir}-width: $border !important;
        border-#{$dir}-style: solid;
      }
    }
  }
}