.new-design {
  $typography: (
    "h1": (24px, 700, 36px),
    "h2": (21px, 700, 32px),
    "h3": (18px, 700, 28px),
    "h4": (16px, 400, 24px),
    "h5": (12px, 500, 18px),
    "body": (16px, 400, 24px),
    "body1": (14px, 400, 22px),
    "action": (16px, 500, 24px),
    "action1": (14px, 500, 22px),
    "caption": (12px, 400, 18px),
    "caption1": (10px, 400, 16px),
  );

@each $class, $values in $typography {
  &.typography-#{$class} {
    font-size: nth($values, 1);
    font-weight: nth($values, 2);
    line-height: nth($values, 3);
  }
}
}

// as example of usage
// <h1 class="new-design h1">Heading 1</h1>