.cloud-status-message {
  width: 100%;
  height: auto;
  min-height: 36px;
  margin: 6px 0 0 0;
  padding: 7px;
  color: #000;
  border: 1px solid #8D968D;
  border-radius: 8px;
  background-color: #fff;
  resize: none;
  overflow: none;
  max-height: 300px;

  &::placeholder {
    color: #000 !important;
  }
}

.cloudStatusModal {
  .modal-body {
    padding-top: 10px !important;
  }

  .modal-footer {
    border-top: 1px solid #40424d !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}

#CloudStatusForm {
  background-color: transparent;

  .MuiTabs-root {
    color: #000;
    padding: 0;
    margin-bottom: 15px;
    min-height: 30px;
    font-weight: 400;
  }

  .MuiButtonBase-root {
    opacity: 1;
    font-size: 15px;
    padding-bottom: 7px;
  }

  .MuiTabs-flexContainer {
    border-bottom: 2px solid #000;
  }

  .Mui-selected {
    color: var(--primary-color) !important;
  }

  .MuiTabs-indicator {
    background-color: var(--primary-color);
    height: 2px;
  }

  .tab-wrapper {
    border: 1px solid #40424d;
    border-radius: 3px;
  }

  .MuiTab-root {
    min-height: 30px !important;
    width: 50%;
    color: #000;
    text-transform: none;
  }
}